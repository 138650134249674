//dev configs
export const DEV_BASE_URL = 'http://localhost'
export const DEV_BASE_API_URL = 'http://localhost:5001/api/v1/'

//old staging
export const STAGING_BASE_URL = 'https://kt.laheri.co.ke'
export const STAGING_BASE_API_URL = 'https://api.kt.laheri.co.ke/api/v1/'

//old prod
export const PRODUCTION_BASE_URL = 'https://portal.kwanzatukule.com'
export const PRODUCTION_BASE_API_URL = 'https://api.portal.kwanzatukule.com/api/v1/'

//new staging on digital ocean
export const STAGING_DG_BASE_URL = 'https://testing-portal.kwanzatukule.com'
export const STAGING_DG_BASE_API_URL = 'https://testing-api.kwanzatukule.com/api/v1/'

//new prod on digital ocean
export const PROD_DG_BASE_URL = 'https://prod-portal.kwanzatukule.com'
export const PROD_DG_BASE_API_URL = 'https://prod-api.kwanzatukule.com/api/v1/'

export let BASE_URL = ''
export let BASE_API_URL = ''
export const location = window && window.location

export const hostname = `${location.protocol}//${location.hostname}`

switch (hostname) {
  case STAGING_BASE_URL:
    BASE_URL = STAGING_BASE_URL
    BASE_API_URL = STAGING_BASE_API_URL
    break

  case PRODUCTION_BASE_URL:
    BASE_URL = PRODUCTION_BASE_URL
    BASE_API_URL = PRODUCTION_BASE_API_URL
    break

  case STAGING_DG_BASE_URL:
    BASE_URL = STAGING_DG_BASE_URL
    BASE_API_URL = STAGING_DG_BASE_API_URL
    break

  case PROD_DG_BASE_URL:
    BASE_URL = PROD_DG_BASE_URL
    BASE_API_URL = PROD_DG_BASE_API_URL
    break

  default:
    BASE_URL = `${DEV_BASE_URL}:${location.port}`
    BASE_API_URL = `${DEV_BASE_API_URL}`
    break
}

console.log('hostname ===> ', hostname)
console.log('BASE_URL ===> ', BASE_URL)
console.log('BASE_API_URL ===> ', BASE_API_URL)
